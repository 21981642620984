import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import EnterMobile from "../../../components/Verification/EnterMobile";
import VerifyOTP from "../../../components/Verification/VerifyOTP";
import LocationAccess from "../../../components/LocationAccess/LocationAccess";

import { IMobileVerify, IOtpVerify } from "../../../models/onboarding";
import { IRoleType } from "../../../models/role";

import {
  mobileVerifyOrSendOTPApi,
  OTPVerifyApi,
} from "../../../utility/apis/onboarding/OnBoardingApis";
import { countryCode } from "../../../utility/text";
import { storage } from "../../../utility/Storage";

const MobileOTPVerify: React.FC = () => {
  const [isMobileVerifyShow, setIsMobileVerifyShow] = useState<boolean | null>(
    true
  );
  const [mobileNoOTPId, setMobileNoOTPId] = useState({
    mobileNo: "",
    otpId: "",
  });
  const [isLocationAccessShow, setIsLocationAccessShow] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const mobileSubmitHandler = async (values: IMobileVerify) => {
    try {
      const mobileVerifyRes = await mobileVerifyOrSendOTPApi({
        country_code: countryCode,
        device_id: "string",
        device_token: "string",
        phone_number: values.mobileNo,
      });
      if ("result" in mobileVerifyRes) {
        setMobileNoOTPId({
          mobileNo: values.mobileNo,
          otpId: mobileVerifyRes?.result?.OtpId,
        });
        setIsMobileVerifyShow(!isMobileVerifyShow);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOTPSubmit = async (values: IOtpVerify) => {
    try {
      const otpVerifyRes = await OTPVerifyApi({
        device_id: "string",
        device_token: "string",
        otp_code: values.otp,
        token: mobileNoOTPId?.otpId,
      });
      if ("result" in otpVerifyRes) {
        storage.setSessionToken("token", otpVerifyRes.result.token);
        storage.setSessionToken("user_id", otpVerifyRes.result.user_id);
        storage.setSessionToken("roleType", otpVerifyRes.result.role);
        storage.setSessionToken("name", otpVerifyRes.result.name);
        if (otpVerifyRes.result.role === IRoleType.DOCTOR) {
          navigate("/schedule");
        } else {
          setIsMobileVerifyShow(null);
          setIsLocationAccessShow(!isLocationAccessShow);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resendOTP = async () => {
    try {
      const mobileVerifyRes = await mobileVerifyOrSendOTPApi({
        country_code: countryCode,
        device_id: "string",
        device_token: "string",
        phone_number: mobileNoOTPId?.mobileNo,
      });
      if ("result" in mobileVerifyRes) {
        setMobileNoOTPId({
          ...mobileNoOTPId,
          otpId: mobileVerifyRes.result.OtpId,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToMobileVerify = () =>
    setIsMobileVerifyShow(!isMobileVerifyShow);

  return (
    <>
      {isMobileVerifyShow ? (
        <EnterMobile
          heading="Login using Phone Number"
          mobileSubmitHandler={mobileSubmitHandler}
        />
      ) : (
        isMobileVerifyShow !== null && (
          <VerifyOTP
            handleOTPSubmit={handleOTPSubmit}
            mobileNo={mobileNoOTPId?.mobileNo}
            navigateToMobileVerify={navigateToMobileVerify}
            resendOTP={resendOTP}
          />
        )
      )}

      {isLocationAccessShow && (
        <LocationAccess
          isOpen={isLocationAccessShow}
          closeHandler={() => setIsLocationAccessShow(!isLocationAccessShow)}
        />
      )}
    </>
  );
};

export default MobileOTPVerify;
